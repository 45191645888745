import React, { useState, useLayoutEffect, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import PlaybtnIcon from '../../Assets/PlaybtnIcon.png';
import bannerImage from '../../Assets/banner-image.png';
import aboutsectionImage from '../../Assets/aboutsection-img.png';
import featureIcon from '../../Assets/feature-icon.png';
import tickIcon from '../../Assets/tick-icon.png';
import elipse1 from '../../Assets/elipse1.png';
import elipse2 from '../../Assets/elipse2.png';
import elipse3 from '../../Assets/elipse3.png';
import elipse4 from '../../Assets/elipse4.png';
import productivityimg1 from '../../Assets/productivityimg1.png';
import productivityimg2 from '../../Assets/productivityimg2.png';
import tickIcon2 from '../../Assets/tickIcon2.png';
import whyimg1 from '../../Assets/whyimg1.png';
import whyimg2 from '../../Assets/whyimg2.png';
import zerodueImage from '../../Assets/zerodueImage.png';
import offeringsImage from '../../Assets/offeringsImage.png';

import benefitssectionimg1 from '../../Assets/benefits-section-img1.png';
import benefitssectionimg11 from '../../Assets/benefitssection-img1.png';
import benefitssectionimg22 from '../../Assets/benefitssection-img2.png';
import benefitssectionimg2 from '../../Assets/benefits-section-img2.png';
import benefitssectionimg33 from '../../Assets/benefitssection-img3.png';
import benefitssectionimg3 from '../../Assets/benefits-section-img3.png';
import benefitssectionimg44 from '../../Assets/benefitssection-img-4.png';
import benefitssectionimg4 from '../../Assets/benefits-section-img4.png';

import offeringIcon from '../../Assets/offering-icon.png';
import offeringIcon2 from '../../Assets/offering-icon2.png';
import filtericon from '../../Assets/filtericon.png';
import { FaArrowRightLong } from "react-icons/fa6";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';

import { Zoom, Slide } from "react-awesome-reveal";
const HomePage = () => {
    const [AboutSection, setAboutSection] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [HoverAccordion, setHoverAccordion] = useState(null);
    const [filter, setFilter] = useState('Daily');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [screenwidth, setscreenwidth] = useState('');
    const navigate = useNavigate();

    const FeatureSectionArray = [
        {
            heading: 'Digital Collection Strategy and Journey',
            text: 'ZeroDue offers tailored digital strategies and processes to guide the debt collection journey. Through optimized digital channels, users can efficiently manage collection efforts, guiding debtors through a seamless resolution process, thereby enhancing recovery rates.',
            points: [
                'Tailored Digital Strategies.',
                'Optimized Digital Channels.',
                'Enhanced Recovery Rates.'
            ],
            id: 'Feature1',
        },
        {
            heading: 'Integrated Omnichannel Communications',
            text: 'ZeroDue ensures seamless communication with clients across multiple channels, including email, SMS, and chat. By consolidating all communication within the platform, users can effectively engage with clients, send reminders, updates, and notifications, ensuring prompt and consistent communication.',
            points: [
                'Multi-Channel Communication.',
                'Centralized Platform.',
                'Consistent Client Engagement.'
            ],
            id: 'Feature2',
        },
        {
            heading: 'AIdriven Classification or Bucketing',
            text: 'ZeroDue utilizes AI technology to classify clients into specific segments or "buckets" based on their behavior and payment patterns. This enables personalized communication strategies tailored to meet the unique needs of each client segment, ultimately improving communication efficiency and debt recovery outcomes.',
            points: [
                'AI-Powered Client Segmentation.',
                'Personalized Communication Strategies.',
                'Improved Debt Recovery.'
            ],
            id: 'Feature3',
        },
        {
            heading: 'Litigation Workflow Management',
            text: 'ZeroDue automates the generation of legal agreements and manages followups related to litigation cases. By streamlining the legal workflow, users can save time and resources, ensuring compliance and efficiency in handling litigation cases.',
            points: [
                'Automated Legal Agreement Generation.',
                'Efficient Litigation Management.',
                'Time and Resource Savings.'
            ],
            id: 'Feature4',
        },
        {
            heading: 'Grievance Handling',
            text: 'ZeroDue centralizes grievance handling, offering a single platform for addressing client concerns and complaints. With prompt and effective resolution, users can enhance customer satisfaction, strengthen client relationships, and maintain high levels of trust.',
            points: [
                'Centralized Grievance Handling.',
                'Prompt and Effective Resolutions',
                'Strengthened Client Relationships.'
            ],
            id: 'Feature5',
        },
        {
            heading: 'Integrated Payments with Multiple Options',
            text: 'ZeroDue provides clients with a variety of payment options seamlessly integrated into the platform. From credit card payments to bank transfers, clients can choose their preferred payment method, enhancing convenience and flexibility while accelerating debt resolution.',
            points: [
                'Multiple Payment Options.',
                'Seamless Integration.',
                'Enhanced Convenience and Flexibility.'
            ],
            id: 'Feature6',
        },
    ];

    const ProductivitySectionArray = [
        {
            image: productivityimg1,
            heading: 'Digital Communication',
            points: [
                'Omnichannel communication via automated sms, whatsapp, app notifications, emails and calls for collection reminders.',
                'Delivery tracking and insights.',
                'Intelligent communication strategy builder.',
                'Intuitive prompts for agents to find out the best channel for establishing communication.'
            ],
            id: 'prod1'
        },
        {
            image: productivityimg2,
            heading: 'Collection Analytics',
            points: [
                'Intelligent Repayment Analysis for customers and raise red flags (early warnings) for tentative to be delinquent customers.',
                'Prebuilt Collection reports and dashboards for collection analytics and performance (multiple timeframes).',
                'Drag and drop report creation.',
                'Automated report generation and delivery over email for management visibility.'
            ],
            id: 'prod2'
        },
        {
            image: productivityimg2,
            heading: 'Integrated Payments',
            points: [
                'EMandate',
                'Net Banking  Third Party',
                'Card Payment',
                'eWallets',
                'BHIM/ UPI',
                'Payment Gateway',
                'Repayment ReScheduling Management',
            ],
            id: 'prod3'
        },
        {
            image: productivityimg2,
            heading: 'Litigation Management',
            points: [
                'Compliance and Litigation tool.',
                'Legal tool & advisory services.',
                'Automatic Legal agreement creation and Followups.',
                'Online delivery of legal agreements over whatsapp and email.',
            ],
            id: 'prod4'
        },
        {
            image: productivityimg2,
            heading: 'Grievance Management',
            points: [
                'Handle all the customer complaints from a single place.',
                'Early visibility into angry customer behaviour and increase customer loyalty by addressing his/her concerns proactively.',
            ],
            id: 'prod5'
        },
        {
            image: productivityimg2,
            heading: 'Offline Collections',
            points: [
                'Manage agents for offline collections.',
                'Geo Tagging & Route Mapping & Location View.',
            ],
            id: 'prod6'
        },
        {
            image: productivityimg2,
            heading: 'Collection Consulting Services',
            points: [
                'Collection Hiring.',
                'Recovery Agency Onboarding.',
                'Agency Negotiation and Portfolio management.',
                'Collection Compliance & Framework for Third party Agency.',
            ],
            id: 'prod7'
        },
        {
            image: productivityimg2,
            heading: 'Debt Restructuring and Securitization',
            points: [
                'Debt Structuring and Fundraising.',
                'Bad Portfolio Auction and Bidding.',
                'Projection and Securitization .',
                'Financial planning and Portfolio Health.',
            ],
            id: 'prod8'
        },
    ];

    const Benefitsdata = [
        {
            img: benefitssectionimg11,
            hoverimg: benefitssectionimg1,
            heading: 'Import debtor data',
            text: 'The first step in the workflow is to import debtor data into the system. This data can be imported from a variety of sources, including spreadsheets, databases, and other systems.'
        },
        {
            img: benefitssectionimg22,
            hoverimg: benefitssectionimg2,
            heading: 'Segmentation',
            text: 'once the debtor data is imported, the system segments the debtors based on various criteria, such as age, location, and amount of debt.'
        },
        {
            img: benefitssectionimg33,
            hoverimg: benefitssectionimg3,
            heading: 'Assignment',
            text: 'The system assigns debtors to the appropriate collection agents based on the segmentation criteria.'
        },
        {
            img: benefitssectionimg44,
            hoverimg: benefitssectionimg4,
            heading: 'Communication',
            text: 'Debt collectors can then communicate with debtors through various channels, such as email, text message, or phone call.'
        },
        {
            img: benefitssectionimg44,
            hoverimg: benefitssectionimg4,
            heading: 'Payment collection',
            text: 'once the debtor agrees to pay, the system facilitates payment collection and updates the payment status in real time.'
        },
        {
            img: benefitssectionimg44,
            hoverimg: benefitssectionimg4,
            heading: 'Reports',
            text: 'The system generates reports that provide information about the collection process, such as the number of debtors contacted, collection rates, and more.'
        },

    ];

    const data = [
        {
            name: 'Jan',
            AutoDebit: 4000,
            InHouse: 2400,
            ThirdParty: 2400,
        },
        {
            name: 'Feb',
            AutoDebit: 3000,
            InHouse: 1398,
            ThirdParty: 2210,
        },
        {
            name: 'Mar',
            AutoDebit: 2000,
            InHouse: 9800,
            ThirdParty: 2290,
        },
        {
            name: 'Apr',
            AutoDebit: 2780,
            InHouse: 3908,
            ThirdParty: 2000,
        },
        {
            name: 'May',
            AutoDebit: 1890,
            InHouse: 4800,
            ThirdParty: 2181,
        },
        {
            name: 'Jun',
            AutoDebit: 2390,
            InHouse: 3800,
            ThirdParty: 2500,
        },
        {
            name: 'Jul',
            AutoDebit: 3490,
            InHouse: 4300,
            ThirdParty: 2100,
        },
        {
            name: 'Aug',
            AutoDebit: 3490,
            InHouse: 4300,
            ThirdParty: 2100,
        },
        {
            name: 'Sep',
            AutoDebit: 3490,
            InHouse: 4300,
            ThirdParty: 2100,
        },
        {
            name: 'Oct',
            AutoDebit: 3490,
            InHouse: 4300,
            ThirdParty: 2100,
        },
        {
            name: 'Nov',
            AutoDebit: 3490,
            InHouse: 4300,
            ThirdParty: 2100,
        },
        {
            name: 'Dec',
            AutoDebit: 3490,
            InHouse: 4300,
            ThirdParty: 2100,
        },
    ];

    useLayoutEffect(() => {
        let img = document.getElementById('bannerImage')?.clientHeight;
        let width = window?.innerWidth
        setscreenwidth(width)
        console.log(img)
        if (width >= 1800) {
            setAboutSection('10%');
        } else if (width < 991 && width > 576) {
            setAboutSection((Number(img) + 100) + 'px');
        } else if (width <= 576) {
            setAboutSection('490px');
        } else {
            setAboutSection('13%');
        }
    }, [])

    const handleArrowClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const HoverAccordionfn = (id) => {
        setHoverAccordion(HoverAccordion === id ? null : id);
    };

    const handleDate = (type, e) => {
        if (type === 'filter') {
            setFilter(e.target.value)
        }
        else if (type === 'fromDate') {
            setFromDate(e.target.value);
        } else if (type === 'toDate') {
            setToDate(e.target.value);
        } else {
            console.log(e)
        }
    }

    const maxFunction = (dataArray) => {
        let maxHeight = 0;
        dataArray.forEach(item => {
            const element = document.getElementById(item.id);
            if (element) {
                const height = element.clientHeight;
                console.log("height", element.id, "----", height)
                if (height > maxHeight) {
                    maxHeight = height;
                }
            }
        });
        console.log("maxHeight", maxHeight)

        return maxHeight;
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const Navigation = (navigateLink) => {
        navigate(navigateLink);

        setTimeout(() => {
            scrollToTop();
        }, 400)
    }

    return (
        <>
            <Navbar />
            <div className="Homepage">
                <div className="banner-section circle-shape" style={{ marginBottom: AboutSection ,overflowx:'clip'}}>
                    <div className="container">
                        <div className="row rowcols">
                            <div className="col-lg-7 col-md-12 mt-4" >
                                <div className="colbox">
                                    <h1>Streamlining Debt Collection and Recovery with <span className='bluecolor'>ZeroDue</span></h1>
                                    <p>Unleashing the Power of our Debt & Recovery SaaS Solution</p>
                                    <div className="btns">
                                        <a className='btn1 me-3 mt-3'>Get Started</a>
                                        <a className="videobtn mt-3">
                                            <div className="backcircle"></div>
                                            <div className="circle">
                                                <img src={PlaybtnIcon} width={'40%'} alt="" />
                                            </div>
                                            <h5>Watch Video</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 mt-4" >
                                    <div className="imgbox text-end" id="bannerImage">
                                <Slide direction='right'>
                                            <img src={bannerImage} alt="BannerImage" width={screenwidth > 1800 ? '80%' : '90%'} />
                                </Slide>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-xs-12">
                                <div className="imgs">
                                    <img src={aboutsectionImage} width={"100%"} alt="About_image" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-xs-12">
                                <div className="texts">
                                    <Zoom>
                                        <h1>About Us</h1>
                                    </Zoom>
                                    <p className='mt-2'>Collection is the backbone of Lending. Zerodue’s state-of-the-art digitally powered technology platform helps to streamline your collections and minimise your bad loans. Our Intelligent and intuitive Product Suite helps you to stay ahead of the collection cycle. Whether you are a bank, NBFC, or 3rd party Collector (big or small) - we have a solution for everyone to handle the complexities in the collection</p>
                                    <button className='btn2 mt-3' onClick={() => Navigation('/about')}>View More <span><FaArrowRightLong /></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="impact-section">
                    <div className="container">
                        <div className="box">
                            <Zoom>
                                <h1 className='heading'>Our Impact</h1>
                            </Zoom>
                            <div className="row boxes">
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <h4>1 <span>B+</span></h4>
                                    <p>Volume Processed</p>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <h4>10 <span>M+</span></h4>
                                    <p>Expert Support Team</p>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <h4>5 <span>L+</span></h4>
                                    <p>Accounts flagged</p>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <h4>1 <span>L+</span></h4>
                                    <p>End Customers</p>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <h4>10<span>+</span></h4>
                                    <p>Financial Institutions trust us</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="features-section" style={{obverflowX:'hidden'}}>
                    <div className="container">
                        <Zoom>
                            <h1 className='heading'>Explore our amazing features</h1>
                        </Zoom>
                        <Slide>
                            <div className="row mt-4">
                                {
                                    FeatureSectionArray.map((value, index) => {
                                        return (
                                            <FeatureCard key={index} image={featureIcon}
                                                tick={tickIcon}
                                                data={value}
                                                isActive={activeIndex === index}
                                                onArrowClick={() => handleArrowClick(index)}
                                                FeatureSectionArray={FeatureSectionArray}
                                                maxFunction={maxFunction} />

                                        )
                                    })
                                }
                            </div>
                        </Slide>
                    </div>
                </div>

                <div className="benefits-section">
                    <div className="container">
                        <div className="box">
                            <Zoom>
                                <h1 className='heading'>Benefits</h1>
                            </Zoom>
                            <div className="row boxes">
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={elipse1} alt="elipse" width={'38%'} />
                                        <h4>60%</h4>
                                        <p>boost in recovery across<br /> delinquent accounts</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={elipse2} alt="elipse" width={'50%'} />
                                        <h4>60%</h4>
                                        <p>reduction in collection costs</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={elipse3} alt="elipse" width={'50%'} />
                                        <h4>60%</h4>
                                        <p>increase in team productivity</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={elipse4} alt="elipse" width={'48%'} />
                                        <h4>60%</h4>
                                        <p>reduction in time to collect</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="productivity-section">
                    <div className="container">
                        <Zoom>
                            <h1 className='heading'>Powerful Modules to boost<br /> your Productivity</h1>
                        </Zoom>
                        <div className="sectioncards">
                            <OwlCarousel className='owl-theme' loop dots={false} responsiveClass={true} responsive={
                                {
                                    0: {
                                        items: 1,
                                        dots: false,
                                        nav: true,
                                        loop: true,
                                        autoplay: true,
                                        autoplayTimeout: 3000,
                                    },
                                    600: {
                                        items: 2,
                                        dots: false,
                                        nav: true,
                                        loop: true,
                                        autoplay: true,
                                        autoplayTimeout: 3000,
                                    },
                                    1000: {
                                        items: 3,
                                        dots: false,
                                        nav: true,
                                        loop: true,
                                        autoplay: true,
                                        autoplayTimeout: 3000,
                                    },
                                    1200: {
                                        items: 3.4,
                                        dots: false,
                                        nav: true,
                                        loop: true,
                                        autoplay: true,
                                        autoplayTimeout: 3000,
                                    }
                                }
                            }>
                                {
                                    ProductivitySectionArray.map((value, index) => {
                                        return (
                                            <ProductivitySectionCard key={index} data={value} tickIcon={tickIcon2} maxFunction={maxFunction} ProductivitySectionArray={ProductivitySectionArray} />
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>

                <div className="why-section">
                    <div className="container">
                        <div className="box">
                            <Zoom>
                                <h1 className='heading'>Why Zerodue ?</h1>
                            </Zoom>
                            <div className="row boxes">
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={whyimg1} alt="whyimg" width={'47%'} />
                                        <h4>Expertise</h4>
                                        <p>Experienced debt collection<br /> professionals adept at handling <br />complex cases.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={whyimg2} alt="whyimg" width={'35%'} />
                                        <h4>Customized Solutions</h4>
                                        <p>We personalize debt recovery<br /> strategies to fit each client's<br /> goals.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={whyimg2} alt="whyimg" width={'35%'} />
                                        <h4>Client Centric</h4>
                                        <p>We value keeping clients<br /> informed and involved through<br /> open communication.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 subbox">
                                    <div className="box1">
                                        <img src={whyimg2} alt="whyimg2" width={'35%'} />
                                        <h4>Proven Track Record</h4>
                                        <p>ZeroDue is renowned for successful<br /> outcomes and satisfied clients in<br /> debt collection.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="workflow-section">
                    <div className="container">
                        <Zoom>
                            <h1 className='heading'>Our Workflow</h1>
                        </Zoom>
                        <div className="row rowss">
                            <div className="col-lg-6 col-sm-12 mt-4">
                                <div className="imgs">
                                    <img src={zerodueImage} alt="zerodueImage" width={'100%'} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 mt-4" style={{ padding: '30px 20px' }}>
                                <div className="hoverablebox">
                                    {Benefitsdata.map((item, index) => (
                                        <HoverableItem
                                            key={index}
                                            id={index}
                                            isOpen={HoverAccordion === index}
                                            HoverAccordionfn={HoverAccordionfn}
                                            item={item}
                                            data1={Benefitsdata}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="offering-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12">
                                <div className="imgs text-center">
                                    <img src={offeringsImage} alt="offeringsImage" width={'70%'} />
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                <div className="texts">
                                    <Zoom>
                                        <h1 className='heading text-start'>Our Offerings</h1>
                                    </Zoom>
                                    <p>Empower Your Collection Strategy with Zerodue</p>

                                    <div className="boxes">
                                        <div className="box1 me-4">
                                            <div className="square">
                                                <img src={offeringIcon} alt="offeringIcon" width={'100%'} />
                                            </div>
                                            <h5>Corporate<br /> Partner</h5>
                                        </div>
                                        <div className="box1">
                                            <div className="square">
                                                <img src={offeringIcon2} alt="offeringIcon2" width={'100%'} />
                                            </div>
                                            <h5>Alliance<br /> Partner</h5>
                                        </div>
                                    </div>

                                    <button className='btn2 mt-5' onClick={() => Navigation('/offerings')}>View More <span><FaArrowRightLong /></span></button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="freeTrial-section mb-5">
                    <div className="container">
                        <div className="common box newbg padding-dec">
                            <div className="subbox">
                                <Zoom>
                                    <h1 className='heading'>Start your free trial today</h1>
                                </Zoom>
                                <p>It is a long established fact that a reader will be <br />by the readable when looking at it layout. </p>
                                <Graph
                                    handleDate={handleDate}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    filter={filter}
                                    data={data} />
                            </div>
                            <div className="GetStarted">
                                <div className="emails">
                                    <input type="email" className='input-field' placeholder='Enter your mail here..' />
                                    <button className='btn1 btnss'>Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default HomePage;

function FeatureCard({ image, tick, data, isActive, onArrowClick, maxFunction, FeatureSectionArray }) {
    const [FeatureSectionHeight, setFeatureSectionHeight] = useState('');

    useLayoutEffect(() => {
        setFeatureSectionHeight(maxFunction(FeatureSectionArray))
    }, [])
    return (
        <>
            <div className={`col-lg-4 col-md-6 col-xs-12 feature-card active`}>
                <div className="box" style={{ height: FeatureSectionHeight }} id={data.id}>
                    <div className="heading">
                        <div className="imgBox">
                            <img src={image} alt="Feature_icon" width={'100%'} />
                        </div>
                        <h5>{data.heading}</h5>
                    </div>
                    <h6 style={{ marginBottom: '30px' }}>{data.text}</h6>

                    <div className={`hiddentexts show`}>
                        {
                            data?.points?.length > 0 ? data.points.map((value, index) => {
                                return (
                                    <div className="subbox" key={index}>
                                        <img src={tick} alt="tick_icon" width={'10%'} />
                                        <h6 className='mt-0'>{value}</h6>
                                    </div>
                                )
                            })
                                : ''
                        }
                    </div>
                    {/* <div className="arrowbox" onClick={onArrowClick}>
                        <span><FaArrowRightLong /></span>
                    </div> */}
                </div>
            </div>
        </>
    )
}
// function FeatureCard({ image, tick, data, isActive, onArrowClick }) {
//     return (
//         <>
//             <div className={`col-lg-4 col-md-6 col-xs-12 feature-card ${isActive ? 'active' : ''}`}>
//                 <div className="box">
//                     <div className="heading">
//                         <div className="imgBox">
//                             <img src={image} alt="Feature_icon" width={'100%'} />
//                         </div>
//                         <h5>{data.heading}</h5>
//                     </div>
//                     <h6 style={{ marginBottom: '30px' }}>{data.text}</h6>

//                     <div className={`hiddentexts ${isActive ? 'show' : ''}`}>
//                         {
//                             data?.points?.length > 0 ? data.points.map((value, index) => {
//                                 return (
//                                     <div className="subbox" key={index}>
//                                         <img src={tick} alt="tick_icon" width={'10%'} />
//                                         <h6 className='mt-0'>{value}</h6>
//                                     </div>
//                                 )
//                             })
//                                 : ''
//                         }
//                     </div>
//                     <div className="arrowbox" onClick={onArrowClick}>
//                         <span><FaArrowRightLong /></span>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

function ProductivitySectionCard({ data, tickIcon, maxFunction, ProductivitySectionArray }) {
    // const [ProductivitySectionHeight, setProductivitySectionHeight] = useState('');

    // useEffect(() => {
    //     let element = document.getElementById('prod2').clientHeight;
    //     setProductivitySectionHeight(element)
    //     setProductivitySectionHeight(maxFunction(ProductivitySectionArray))
    // },[])
    return (
        <>
            <div className='col-xs-12 cards'>
                <div className="box" style={{ height: '563px' }} id={data.id}>
                    <div className="subbox mb-3">
                        <img src={data.image} alt="icon" />
                        <h5>{data.heading}</h5>
                    </div>
                    <div className="lists">
                        {data?.points?.length > 0 ? data?.points.map((value, index) => {
                            return (
                                <div className="subboxes" key={index}>
                                    <img src={tickIcon} alt="tick_icon" />
                                    <h5>{value}</h5>
                                </div>
                            )
                        }) : ''}
                    </div>
                </div>
            </div>
        </>
    )
}
function HoverableItem({ id, isOpen, HoverAccordionfn, item }) {
    return (
        <div className={`accords ${isOpen ? 'openaccord' : ''}`} >
            <div className="heading" onMouseEnter={() => HoverAccordionfn(id)} onMouseLeave={() => HoverAccordionfn(null)}>
                <div className="box">
                    <h5><img src={isOpen ? item.hoverimg : item.img} width={'4%'} alt="" className='me-2' />{item.heading}</h5>
                </div>
                {isOpen && (
                    <div className="bottomtext">
                        <p>{item.text}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

function Graph({ handleDate, fromDate, toDate, filter, data }) {
    return (
        <>
            <div className='LineGraph'>
                <div className="Filters">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 col-xs-12 d-flex justify-content-between mt-2">
                            <div className="left" style={{ width: '90%' }}>
                                <h5><img src={filtericon} alt="filtericon" width={'10%'} />&nbsp; Filter</h5>
                                <select className='select-box' onChange={(e) => handleDate('filter', e)} value={filter}>
                                    <option value="Daily">Daily</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                </select>
                            </div>
                            <div className='symboldiv'>
                                <h5 className='symbol'>: :</h5>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-xs-12 d-flex mt-2">
                            <div className="left w-100">
                                <h5>From</h5>
                                <input type="date" className='select-box greycolor' value={fromDate} onChange={(e) => handleDate('fromDate', e)} />
                            </div>
                            <div className="symboldiv">
                                <h5 className='symbol'>-</h5>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-2">
                            <h5>To</h5>
                            <input type="date" className='select-box greycolor' value={toDate} min={fromDate} onChange={(e) => handleDate('toDate', e)} />
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-2">
                            <button className='btn3' style={{ marginTop: '9%' }} onClick={(e) => handleDate(e)}>Apply Filter</button>
                        </div>
                    </div>
                </div>

                <div className="graph">
                    <div className="heading d-flex mb-4">
                        <div className="lefttext">
                            <h5>Collected Amount</h5>
                        </div>
                        <div className="righttext">
                            <div className='typegraph me-3'><h5>{filter}</h5></div>
                            <div className="legend me-3">
                                <div className="circle me-2" style={{ backgroundColor: '#347AE2' }}></div>
                                <h5>Inhouse Agents</h5>
                            </div>
                            <div className="legend me-3">
                                <div className="circle me-2" style={{ backgroundColor: '#4CC78C' }}></div>
                                <h5>Auto Debit</h5>
                            </div>
                            <div className="legend me-3">
                                <div className="circle me-2" style={{ backgroundColor: '#FF9500' }}></div>
                                <h5>Third Party Agents</h5>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-1 mb-3" style={{ height: '400px' }}>
                        <div className="chart px-4" style={{ height: '400px' ,overflowX:'auto'}}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart width={300} height={100} data={data}>
                                    <CartesianGrid horizontal={true} vertical={false} />
                                    <XAxis dataKey="name" axisLine={false} />
                                    <YAxis axisLine={false} />
                                    <Tooltip content={<CustomTooltip />}
                                        cursor={{ fill: "transparent" }}
                                        shared={false} />
                                    <Line type="monotone" dataKey="InHouse" stroke="#347AE2" activeDot={{ r: 8 }} />
                                    <Line type="monotone" dataKey="AutoDebit" stroke="#4CC78C" activeDot={{ r: 8 }} />
                                    <Line type="monotone" dataKey="ThirdParty" stroke="#FF9500" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

function CustomTooltip({ active, payload, label }) {
    if (active && payload && payload?.length) {
        return (
            <div className="custom-tooltip">
                <div>
                    {payload.map((pld) => (
                        <div style={{ display: "inline-block", padding: 10, backgroundColor: "#182D64", color: "white" }}>
                            <div style={{ color: "#E5E5EF", fontSize: "14px", fontWeight: "400" }}>{pld.name}</div>
                            <div style={{ fontweight: "700", fontSize: "16px", color: "white" }}>{pld.value}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return null;
}